import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useAuthContext } from './AuthContext'

import { useGetTranslations } from '@/i18n/index'

import { LANGUAGES, NOTIFICATION_TYPE } from '@/constants/constants.ts'

import { vanillaTRPC as trpc } from '@/utils/trpc.ts'


import { ApplicationState, ExtendedNotificationT, ExtendedVenueSettingsT, ToastNotificationT } from '@/types/globalTypes'

type ApplicationContextValue = {
    applicationState: ApplicationState
    setApplicationState: React.Dispatch<React.SetStateAction<ApplicationState>>
    toastNotification: ToastNotificationT
    setToastNotification: React.Dispatch<React.SetStateAction<ToastNotificationT>>
    setUserNotifications: React.Dispatch<React.SetStateAction<ExtendedNotificationT[]>>
    userNotifications: ExtendedNotificationT[]
}

type ApplicationProviderProps = {
    children: React.ReactNode
    setVenueLoading: React.Dispatch<React.SetStateAction<boolean>>
    setToastNotification: React.Dispatch<React.SetStateAction<ToastNotificationT>>
    toastNotification: ToastNotificationT
}

export const ApplicationContext = createContext<ApplicationContextValue | null>(null)

const ApplicationProvider: React.FC<ApplicationProviderProps> = ({
    children,
    setVenueLoading,
    setToastNotification,
    toastNotification,
}) => {
    const { supabaseClient } = useAuthContext()
    const { TOAST_NOTIFICATIONS_TEXT } = useGetTranslations()

    const [applicationState, setApplicationState] = useState<ApplicationState>({
        selectedLanguage: LANGUAGES.EN,
        venue: {
            id: '',
            name: '',
            address: '',
            capacity: null,
            working_hours: [],
            active_working_hours: {
                venue_id: '',
                day_of_week: 1,
                ending_hours: '',
                is_active: false,
                starting_hours: '',
            },
            days_of_operation: [],
            allow_reservations: true,
            starting_hours: '',
            ending_hours: '',
            phone_number: '',
            email: '',
            website: '',
            youtube_link: '',
            instagram_link: '',
            linkedin_link: '',
            tiktok_link: '',
            facebook_link: '',
            payment_methods: [],
            description: '',
            latitude: null,
            longitude: null,
            venue_type: null,
            duration: null,
            currency: '',
            max_calendar_days: 0,
            initial_setup: true,
            logo_url: '',
            banner_url: '',
            venue_timezone: '',
            languages: [],
            map_url: '',
            reservation_lead_time: '',
            online_payments: false,
            skip_auto_pin_renewal: false,
        },
    })

    const [userNotifications, setUserNotifications] = useState<ExtendedNotificationT[]>([])

    useEffect(() => {
        ;(async () => {
            try {
                setVenueLoading(true)
                const venue_settings = await trpc.venueSettings.getVenueInformation.query()

                setApplicationState((prev) => {
                    const contextCopy = { ...prev }
                    contextCopy.venue = venue_settings as ExtendedVenueSettingsT
                    return contextCopy
                })

                setVenueLoading(false)
            } catch (error) {
                setToastNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
                })
                setVenueLoading(false)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setToastNotification, setVenueLoading, supabaseClient])

    const providerValue = useMemo(() => {
        return {
            applicationState,
            setApplicationState,
            toastNotification,
            setToastNotification,
            setUserNotifications,
            userNotifications,
        }
    }, [applicationState, toastNotification, setToastNotification, userNotifications])

    return (
        <ApplicationContext.Provider value={providerValue}>{children}</ApplicationContext.Provider>
    )
}

export const useApplicationContext = () => {
    const context = useContext(ApplicationContext)

    if (!context) {
        throw new Error('Application Context must be used within as ApplicationProvider')
    }
    return context
}

export default ApplicationProvider
