import { createTRPCNext } from '@trpc/next';
import { httpBatchLink, createTRPCClient } from "@trpc/client";
import { type AppRouter } from "@/server/routers/_app";
import { DEFAULT_LOCALE, LANGUAGES } from '@/constants/constants';

function getBaseUrl() {
    if (typeof window !== 'undefined')
        // browser should use relative path
        return '';
    if (process.env.NODE_ENV !== 'development') {
        return process.env.NEXT_PUBLIC_FRONTEND_BASE_URL;
    }

    return `http://localhost:${process.env.PORT ?? 3000}`;
}

export const getServerSideHostname = (url: string) => new URL(url).hostname;

export function getLocaleFromURL(url: string | undefined) {
    if (!url) return DEFAULT_LOCALE;
    const locales = Object.keys(LANGUAGES);

    const slugLocale = url.split("/")[3];
    const usingSlugLocale = locales.includes(slugLocale.toUpperCase() || "");
    const topLevelDomainLocale = getServerSideHostname(url).split(".").pop();
    const usingTopLevelDomainLocale = locales.includes(
        topLevelDomainLocale || ""
    );

    if (usingSlugLocale) return slugLocale!;
    if (usingTopLevelDomainLocale) return topLevelDomainLocale!;

    return DEFAULT_LOCALE;
}

// Create the tRPC client
export const trpc = createTRPCNext<AppRouter>({
    config(opts) {
        return {
            links: [
                httpBatchLink({
                    /**
                     * If you want to use SSR, you need to use the server's full URL
                     * @see https://trpc.io/docs/v11/ssr
                     **/
                    url: `${getBaseUrl()}/api/trpc`,

                }),

            ],
        };
    },
    /**
     * @see https://trpc.io/docs/v11/ssr
     **/
    ssr: false,
});


export const vanillaTRPC = createTRPCClient<AppRouter>({
    links: [
        httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
        }),

    ],
});
