export enum ENUM_ACTION_TYPES {
    INIT_DATA = 'INIT_DATA',
    INIT_RESERVATIONS_DASHBOARD_DATA = 'INIT_RESERVATIONS_DASHBOARD_DATA',
    INIT_ORDERS_DASHBOARD_DATA = 'INIT_ORDERS_DASHBOARD_DATA',
    INSERT_ORDER = 'INSERT_ORDER',
    UPDATE_ORDER = 'UPDATE_ORDER',
    UPDATE_ORDERS = 'UPDATE_ORDERS',
    DELETE_ORDER = 'DELETE_ORDER',
    INSERT_RESERVATION = 'INSERT_RESERVATION',
    UPDATE_RESERVATION = 'UPDATE_RESERVATION',
    UPDATE_RESERVATIONS = 'UPDATE_RESERVATIONS',
    DELETE_RESERVATION = 'DELETE_RESERVATION',
    DELETE_RESERVATIONS = 'DELETE_RESERVATIONS',
    INSERT_TABLE = 'INSERT_TABLE',
    DELETE_TABLE = 'DELETE_TABLE',
    UPDATE_TABLE = 'UPDATE_TABLE',
    UPDATE_TABLES = 'UPDATE_TABLES',
    COPY_DEFAULT_TABLE_LAYOUT = 'COPY_DEFAULT_TABLE_LAYOUT',
    ASSIGN_TABLE_TO_WAITER = 'ASSIGN_TABLE_TO_WAITER',
    INSERT_FLOOR = 'INSERT_FLOOR',
    UPDATE_FLOOR = 'UPDATE_FLOOR',
    DELETE_FLOOR = 'DELETE_FLOOR',
    DELETE_SECTION = 'DELETE_SECTION',
    INSERT_SECTION = 'INSERT_SECTION',
    UPDATE_SECTION = 'UPDATE_SECTION',
    UPDATE_CUSTOMER_TABLE = 'UPDATE_CUSTOMER_TABLE',
    UPDATE_CUSTOMER_RESERVATION = 'UPDATE_CUSTOMER_RESERVATION',
    DELETE_CUSTOMER_RESERVATION = 'DELETE_CUSTOMER_RESERVATION',
    GET_CUSTOMER_ORDERS = 'GET_CUSTOMER_ORDERS',
    ADD_ORDER = 'ADD_ORDER',
    UPDATE_CUSTOMER_ORDER = 'UPDATE_CUSTOMER_ORDER',
    UPDATE_RESERVATION_ACTION = 'UPDATE_RESERVATION_ACTION',
    DELETE_CUSTOMER_ORDER = 'DELETE_CUSTOMER_ORDER',
}

export enum ENUM_PERIOD_RANGE_TYPE {
    CONSECUTIVE = 'consecutive',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

export enum ENUM_ORDER_BUTTON_TYPES {
    VIEW_ORDER = 'VIEW_ORDER',
    EDIT_ORDER = 'EDIT_ORDER',
    COMPLETE_ORDER = 'COMPLETE_ORDER',
    DELIVER_ORDER = 'DELIVER_ORDER',
    SET_READY = 'SET_READY',
    SET_IN_PROGRESS = 'SET_IN_PROGRESS',
    ACCEPT_ORDER = 'ACCEPT_ORDER',
    CANCEL_ORDER = 'CANCEL_ORDER',
}

export enum ENUM_RESERVATION_ACTIONS {
    REMOVE_RESERVATION = 'REMOVE_RESERVATION',
    RELOCATE_RESERVATION_ACTION = 'RELOCATE_RESERVATION_ACTION',
    ASSIGN_RESERVATION_ACTION = 'ASSIGN_RESERVATION_ACTION',
    COMPLETE_RESERVATION = 'COMPLETE_RESERVATION',
}

export enum ENUM_MODES {
    CREATE = 'create',
    EDIT = 'edit',
    VIEW = 'view',
    DUPLICATE = 'duplicate',
}

export enum ENUM_PAGE_MODES {
    RESERVATIONS = 'reservations',
    ORDERS = 'orders',
    CUSTOMER = 'customer',
    ADMIN = 'admin',
}

export enum ENUM_HORECA_VENUE_TYPES {
    HOTEL = 'HOTEL',
    RESTAURANT = 'RESTAURANT',
    CAFE = 'CAFE',
    BAR = 'BAR',
    CLUB = 'CLUB',
    FAST_FOOD = 'FAST_FOOD',
    CASUAL_DINING = 'CASUAL_DINING',
    FINE_DINING = 'FINE_DINING',
    BUFFET = 'BUFFET',
    LOUNGE = 'LOUNGE',
}

export enum ENUM_STATUS_CONSTANTS {
    CONFIRMED = 'confirmed',
    PENDING = 'pending',
    DECLINED = 'declined',
    // SEATED= "seated",
    COMPLETED = 'completed',
}

export enum ENUM_ORDER_STATUS_CONSTANTS {
    ACCEPTED = 'accepted',
    PENDING = 'pending',
    IN_PROGRESS = 'in_progress',
    READY = 'ready',
    SERVED = 'served',
    CANCELLED = 'cancelled',
    REFUNDED = 'refunded',
    PAID = 'paid',
    NONE = 'none',
}

export enum ENUMERATED_ROLES {
    WAITER = 'WAITER',
    BARMAN = 'BARMAN',
    HOST = 'HOST',
    MANAGER = 'MANAGER',
    OWNER = 'OWNER',
    CUSTOMER = 'CUSTOMER',
    KITCHEN_STAFF = 'KITCHEN_STAFF',
}

export enum ERROR_CODES {
    DEFAULT = 'DEFAULT',
    SERIALIZATION_FAILURE = '40001',
    OVERLAP_CONFLICT = 'OVERLAP_CONFLICT',
    VENUE_CLOSED = 'VENUE_CLOSED',
    INVALID_TIME = 'INVALID_TIME',
    TABLE_NOT_FOUND = 'TABLE_NOT_FOUND',
    CAPACITY_MISMATCH = 'CAPACITY_MISMATCH',
    NO_TABLES_AVAILABLE = 'NO_TABLES_AVAILABLE',
    INVALID_PARAMETERS = 'INVALID_PARAMETERS',
    WORKING_HOURS_NOT_FOUND = 'WORKING_HOURS_NOT_FOUND'
}
