import { captureException } from '@sentry/nextjs'

import supabaseBrowserClient from '@/lib/client'

import { MODE_CONSTANTS, LANGUAGES } from '@/constants/constants.ts'

import { ENUM_MODES } from '@/enums/Enums'

import { flattenMenuItemData, getNewImageUrl } from '@/utils/functionUtils'

import {
    ExtendedCategoryT,
    ExtendedMenusT,
    ExtendedVenueSettingsT,
    LocalizationT,
    MappedWorkingHoursT,
    MenuCategoryT,
    MenuItemNutritionT,
    MenuItemsGroupCategoriesT,
    ModifiedExtendedCategoryT,
    ModifiedExtendedMenuItemT,
    ModifiedExtendedOptionalItemT,
    ModifiedMappedExtendedEventsT,
    ModifiedMappedGroupTWithMappedLocale,
    ReservationT,
    SectionLocalizationT,
    SectionT,
    TCreateUserEditUserFormValues,
    TMappedLocalization,
    TMappedSectionLocalization,
    TableT,
} from '@/types/globalTypes'
import { Json } from '@/types/supabase.types'

const useAdminPanelServiceApi = () => {
    const supabaseClient = supabaseBrowserClient


    const getUserProfiles = async (roles: string[], pageIndex = 0, pageSize = 20) => {
        const offset = pageIndex * pageSize

        let {
            data: profiles,
            error,
            count,
        } = await supabaseClient
            .from('profiles')
            .select('*')
            .overlaps('roles', [roles])
            .range(offset, offset + pageSize - 1)

        if (error) {
            captureException(error)
        }

        return { profiles, error, count }
    }

    const deleteUserProfile = async (userId: string) => {
        const { data, error } = await supabaseClient.functions.invoke('admin-delete-user', {
            body: {
                userIdToDelete: userId,
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const createUserProfile = async (user: TCreateUserEditUserFormValues) => {
        const { data, error } = await supabaseClient.functions.invoke('admin-create-user', {
            body: {
                user,
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }
    const updateUserProfile = async (user: TCreateUserEditUserFormValues) => {
        const { data, error } = await supabaseClient.functions.invoke('admin-update-user', {
            body: {
                user,
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }
    const getDefaultTablesLayout = async (venue_id: string) => {
        const { data, error } = await supabaseClient.from('default_tables_layout').select('*').eq('venue_id', venue_id)

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const deleteDefaultTable = async (tableId: string) => {
        const { error } = await supabaseClient
            .from('default_tables_layout')
            .delete()
            .eq('id', tableId)

        if (error) {
            captureException(error)
        }

        return error
    }
    const updateDefaultTable = async (tableData: TableT) => {
        const { data, error } = await supabaseClient
            .from('default_tables_layout')
            .update({ ...tableData })
            .eq('id', tableData.id)
            .select()

        if (error) {
            captureException(error)
        }

        return { data: data[0], error }
    }

    const bulkUpdateDefaultTable = async (tableData: TableT[]) => {
        const { data, error } = await supabaseClient
            .from('default_tables_layout')
            .upsert([...tableData])
            .select()

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const createDefaultTable = async (tableData: TableT) => {

        const { data: { session } } = await supabaseClient.auth.getSession();

        const response = await fetch('/api/create-table', {
            body: JSON.stringify({ tableData }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`,
            },
            method: 'POST',
        })

        const { data, error } = await response.json()

        if (!response.ok) {
            captureException(data.error)
        }

        return { data, error } as { data: { table: TableT, reservation: ReservationT & { pin_code: string }, }, error: Error }
    }

    const getFloorsLayout = async (venue_id: string) => {
        const { data: floors, error: floorsError } = await supabaseClient.from('floors').select(`id,
        floors_localization (
            *
          )
        `)
            .eq('venue_id', venue_id)

        if (floorsError) {
            captureException(floorsError)
        }

        return { floors, floorsError }
    }
    const getSectionsLayout = async (venue_id: string) => {
        const { data: sections, error: sectionsError } = await supabaseClient.from('sections')
            .select(`id,
                venue_id,
            floor_id,
            styles,
            x_coordinate,
            y_coordinate,
        sections_localization(*)`)
            .eq('venue_id', venue_id)

        if (sectionsError) {
            captureException(sectionsError)
        }

        return { sections, sectionsError }
    }

    const deleteFloor = async (floorId: string) => {
        const { error } = await supabaseClient.from('floors').delete().eq('id', floorId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const createOrUpdateFloor = async (data: {
        id: string
        floors_localization: { [langCode: string]: string },
        venue_id: string
    }) => {
        // Upsert floor
        let { data: floorData, error: floorError } = await supabaseClient
            .from('floors')
            .upsert([{ id: data.id, venue_id: data.venue_id }])
            .select()

        if (floorError) {
            captureException(floorError)
            return
        }

        const floorId = floorData[0].id || data.id

        // Prepare the translations for upsert
        const translationsToUpsert = Object.entries(data.floors_localization).map(
            ([langCode, translatedName]) => ({
                floor_id: floorId,
                language_code: langCode,
                translated_name: translatedName,

            })
        )

        // Upsert floor_localization
        const { data: localizationData, error: localizationError } = await supabaseClient
            .from('floors_localization')
            .upsert(translationsToUpsert, { onConflict: 'floor_id, language_code' })
            .select()

        if (localizationError) {
            captureException(localizationError)
            return { localizationData, localizationError }
        }

        return { localizationData, localizationError }
    }

    const createOrUpdateSection = async (sectionData: TMappedSectionLocalization) => {

        // Upsert section
        let { data: sectionResponse, error: sectionError } = await supabaseClient
            .from('sections')
            .upsert([{
                id: sectionData.id,
                floor_id: sectionData.floor_id,
                venue_id: sectionData.venue_id,
                styles: sectionData.styles,
                x_coordinate: sectionData.x_coordinate,
                y_coordinate: sectionData.y_coordinate
            }])
            .select()

        if (sectionError) {
            captureException(sectionError)
            console.error('Error upserting section:', sectionError)
            return
        }

        const sectionId = sectionResponse[0].id || sectionData.id

        // Prepare the translations for upsert
        const translationsToUpsert = Object.entries(sectionData.sections_localization).map(
            ([langCode, translatedName]) => ({
                section_id: sectionId,
                language_code: langCode,
                translated_name: translatedName,
                floor_id: sectionData.floor_id,
            }) as SectionLocalizationT
        )


        // Upsert sections_localization
        const { data: localizationData, error: localizationError } = await supabaseClient
            .from('sections_localization')
            .upsert(translationsToUpsert, { onConflict: 'section_id,language_code' })
            .select()

        if (localizationError) {
            captureException(localizationError)
            return { localizationData, localizationError }
        }

        const mappedLocalizationData = translationsToUpsert.map((item) => ({
            ...item,
            floor_id: sectionData.floor_id,
        }))

        return { localizationData: mappedLocalizationData, sectionError }
    }

    const updateSection = async (sectionData: TMappedSectionLocalization) => {

        // Upsert section
        let { data: sectionResponse, error: sectionError } = await supabaseClient
            .from('sections')
            .update({
                floor_id: sectionData.floor_id,
                venue_id: sectionData.venue_id,
                styles: sectionData.styles,
                x_coordinate: sectionData.x_coordinate,
                y_coordinate: sectionData.y_coordinate
            })
            .eq('id', sectionData.id)
            .select()


        if (sectionError) {
            captureException(sectionError)
            console.error('Error upserting section:', sectionError)
            return
        }
        return { sectionResponse, sectionError }
    }
    const bulkUpdateSections = async (sectionData: SectionT[]) => {

        // Upsert section
        let { data: sectionResponse, error: sectionError } = await supabaseClient
            .from('sections')
            .upsert([...sectionData])
            .select(`id,
                venue_id,
            floor_id,
            styles,
            x_coordinate,
            y_coordinate,
        sections_localization(*)`)


        if (sectionError) {
            captureException(sectionError)
            console.error('Error upserting section:', sectionError)
            return
        }
        return { sectionResponse, sectionError }
    }

    const deleteSection = async (sectionId: string) => {
        const { error } = await supabaseClient.from('sections').delete().eq('id', sectionId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const getAllCategories = async (locale: string) => {
        const { data, error } = await supabaseClient.from('categories').select(`
       *,
        categories_localization (
          label,
          description,
          language_code,
          category_id
        )
      `)

        if (error) {
            captureException(error)
        }

        if (data) {
            const flattenedData = data.map((category) => {
                let localization: LocalizationT = category.categories_localization.find(
                    (loc) => loc.language_code === locale
                )
                // If not found, use the English localization as a fallback.
                if (!localization) {
                    localization = category.categories_localization.find(
                        (loc) => loc.language_code === LANGUAGES.EN
                    ) || {
                        label: '',
                        description: '',
                        category_id: '',
                        instruction: '',
                        language_code: '',
                        name: '',
                    }
                }

                return {
                    ...category,
                    label: localization.label,
                    description: localization.description,
                }
            })

            return { data: flattenedData, error }
        }

        return { data, error }
    }

    const createOrUpdateCategory = async (data: ModifiedExtendedCategoryT, mode: string) => {
        try {
            const { categories_localization, ...restOfData } = data;

            // If in EDIT mode, include the category ID for the upsert operation
            if (mode === MODE_CONSTANTS.EDIT) {
                restOfData.id = data.id;
            }

            const localizationsToUpsert = Object.entries(categories_localization).map(
                ([langCode, localization]) => ({
                    language_code: langCode,
                    label: localization.label,
                })
            );

            const { data: { session } } = await supabaseClient.auth.getSession();

            const response = await fetch('/api/create-or-update-category', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    _rest_of_data: restOfData,
                    _localizations_to_upsert: localizationsToUpsert,
                }),
            });

            const result = await response.json();

            if (result.error) {
                captureException(result.error);
                console.error('Error in create_or_update_category:', result.error);
                return [null, result.error];
            }

            return [result.data, null];
        } catch (error) {
            captureException(error);
            console.error('Error in create_or_update_category:', error);
            return [null, error];
        }
    };

    const deleteCategory = async (categoryId: string) => {
        const { error } = await supabaseClient.from('categories').delete().eq('id', categoryId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const uploadImage = async (url: string, file: Blob, bucketName = 'public') => {
        const { error } = await supabaseClient.storage.from(bucketName).upload(url, file, {
            cacheControl: '3600',
            upsert: true,
        })

        if (error) {
            captureException(error)
        }

        return error
    }

    const downloadImage = async (url: string, bucketName = 'public') => {
        const { data, error } = await supabaseClient.storage.from(bucketName).download(url)

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const updateImage = async (url: string, file: Blob, bucketName = 'public') => {
        const { data, error } = await supabaseClient.storage.from(bucketName).update(url, file, {
            cacheControl: '3600',
            upsert: true,
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const handleImageUploadOrUpdate = async ({
        imageFile,
        mode,
        image_url,
        tempFileUrl,
        bucket,
    }: {
        imageFile: File
        mode: string
        image_url: string
        tempFileUrl: string
        bucket: string
    }) => {
        let imageUrl: string

        // Conditions for when we need to upload or update the image.
        // Fixed condition with proper parentheses for clarity and correctness
        const shouldUpload = mode === MODE_CONSTANTS.CREATE && imageFile && !image_url
        const shouldUpdate =
            (mode === MODE_CONSTANTS.EDIT || mode === MODE_CONSTANTS.DUPLICATE) &&
            imageFile &&
            image_url !== tempFileUrl

        if (shouldUpload || shouldUpdate) {
            const error = await uploadImage(imageFile.name, imageFile, bucket)

            if (error) {
                captureException(error)
            }

            imageUrl = getNewImageUrl(imageFile.name, bucket)
        } else {
            imageUrl = image_url
        }

        return imageUrl
    }

    const createOrUpdateMenuItem = async (
        data: ModifiedExtendedMenuItemT,
        nutritionData: MenuItemNutritionT,
        selectedCategories: MenuItemsGroupCategoriesT[],
        initialMenuItemState: ModifiedExtendedMenuItemT,
        optionalGroups: ModifiedMappedGroupTWithMappedLocale[],
        mode: string
    ) => {
        try {
            const {
                menu_items_group_categories,
                menu_item_nutrition,
                menu_items_localization,
                menu_item_optional_groups,
                id,
                ...restOfData
            } = data;

            let itemData = mode === MODE_CONSTANTS.EDIT ? { ...restOfData, id } : restOfData;

            // Prepare categories
            let categoriesToUpsert = [];
            let categoriesToDelete = [];

            if (mode === MODE_CONSTANTS.CREATE || mode === MODE_CONSTANTS.DUPLICATE) {
                categoriesToUpsert = selectedCategories.map((category) => ({
                    category_id: category.category_id,
                    is_active: true,
                }));
            } else if (mode === MODE_CONSTANTS.EDIT) {
                const existingCategoryIds = initialMenuItemState.menu_items_group_categories.map(
                    (category) => category.category_id
                );
                const newCategoryIds = selectedCategories.map((category) => category.category_id);

                categoriesToDelete = existingCategoryIds.filter((id) => !newCategoryIds.includes(id));
                const categoriesToInsert = newCategoryIds.filter(
                    (id) => !existingCategoryIds.includes(id)
                );

                categoriesToUpsert = selectedCategories
                    .filter((category) => categoriesToInsert.includes(category.category_id))
                    .map((category) => ({
                        category_id: category.category_id,
                        is_active: true,
                    }));
            }

            // Prepare option groups
            let optionGroupsToUpsert = [];
            let optionGroupsToDelete = [];

            if (mode === MODE_CONSTANTS.CREATE || mode === MODE_CONSTANTS.DUPLICATE) {
                optionGroupsToUpsert = optionalGroups.map((group) => ({
                    option_group_id: group.optionGroupId || group.id,
                }));
            } else if (mode === MODE_CONSTANTS.EDIT) {
                const existingOptionGroupIds = menu_item_optional_groups.map(
                    (group) => group.option_group_id || group.id
                );
                const newOptionGroupIds = optionalGroups.map((group) => group.optionGroupId || group.id);

                optionGroupsToDelete = existingOptionGroupIds.filter(
                    (id) => !newOptionGroupIds.includes(id)
                );
                const optionGroupsToInsert = newOptionGroupIds.filter(
                    (id) => !existingOptionGroupIds.includes(id)
                );

                optionGroupsToUpsert = optionalGroups
                    .filter((group) => optionGroupsToInsert.includes(group.optionGroupId || group.id))
                    .map((group) => ({
                        option_group_id: group.optionGroupId || group.id,
                    }));
            }

            // Prepare localizations
            const localizationsToUpsert = Object.entries(menu_items_localization).map(
                ([langCode, localization]) => ({
                    language_code: langCode,
                    name: localization.name,
                    description: localization.description,
                })
            );

            const { data: { session } } = await supabaseClient.auth.getSession();

            const response = await fetch('/api/create-or-update-menu-item', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    _rest_of_data: itemData,
                    _nutrition_data: nutritionData,
                    _categories_to_delete: categoriesToDelete,
                    _categories_to_upsert: categoriesToUpsert,
                    _localizations_to_upsert: localizationsToUpsert,
                    _option_groups_to_delete: optionGroupsToDelete,
                    _option_groups_to_upsert: optionGroupsToUpsert,
                }),
            });

            const result = await response.json();

            if (result.error) {
                captureException(result.error);
                console.error('Error in create_or_update_menu_item:', result.error);
                return { menuItemData: null, menuItemError: result.error };
            }

            return { menuItemData: result.data, menuItemError: null };
        } catch (error) {
            captureException(error);
            console.error('Error in create_or_update_menu_item:', error);
            return { menuItemData: null, menuItemError: error };
        }
    };

    const getAllMenuItems = async (
        categories: ExtendedCategoryT[],
        offset = 0,
        limit = 10,
        locale: string = LANGUAGES.EN
    ) => {
        const { data, error } = await supabaseClient
            .from('menu_items')
            .select(
                `*,
                    menu_items_localization (
                    *
                    ),
                    menu_item_nutrition (
                    *
                    ),
                    menu_items_group_categories (
                    *
                    ),
                    menu_item_optional_groups:menu_item_option_groups (
                        *
                    )
                `
            )
            .range(offset, offset + limit - 1)

        if (data) {
            const flattenedData = flattenMenuItemData(data, locale, categories)

            return { menuItemsData: flattenedData, menuItemsError: null }
        }

        if (error) {
            captureException(error)
        }

        return { menuItemsData: null, menuItemsError: error }
    }

    const deleteMenuItem = async (id: string) => {
        const { error } = await supabaseClient.from('menu_items').delete().eq('id', id)

        if (error) {
            captureException(error)
        }

        return error
    }

    const searchMenuItemsStaff = async ({
        search_value = '',
        apply_category_active_condition = false,
        apply_item_active_condition = false,
        apply_searchable_condition = true,
        include_group_categories = false,
        include_localization = false,
        include_nutrition = false,
        include_optional_groups = false,
        language_code = LANGUAGES.EN,
    }: {
        search_value: string,
        apply_category_active_condition: boolean,
        apply_item_active_condition: boolean,
        apply_searchable_condition: boolean,
        include_group_categories: boolean,
        include_localization: boolean,
        include_nutrition: boolean,
        include_optional_groups: boolean,
        language_code: string
    }) => {
        try {
            const { data: { session } } = await supabaseClient.auth.getSession();

            const queryParams = new URLSearchParams({
                search_value,
                apply_category_active_condition: String(apply_category_active_condition),
                apply_item_active_condition: String(apply_item_active_condition),
                apply_searchable_condition: String(apply_searchable_condition),
                include_group_categories: String(include_group_categories),
                include_localization: String(include_localization),
                include_nutrition: String(include_nutrition),
                include_optional_groups: String(include_optional_groups),
                language_code,
            });

            const response = await fetch(`/api/staff-search-menu-items?${queryParams}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            const result = await response.json();

            if (result.error) {
                throw result.error;
            }

            return { data: result.data, error: null };
        } catch (error) {
            captureException(error);
            console.error('Error searching menu items:', error);
            return { data: null, error };
        }
    };

    const getAllOptionGroups = async (offset = 0, limit = 10) => {
        const { data, error } = await supabaseClient
            .from('option_groups')
            .select(
                `
        *,
        option_groups_localization (
           *
        ),
        optional_menu_items_group (*),
        option_group_items: menu_items (
            *,
            option_items_localization: menu_items_localization (
               *
            )
        )
    `
            )
            .range(offset, offset + limit - 1)
            .order('id')

        if (error) {
            captureException(error)
        }

        return { optionalGroups: data, groupsError: error }
    }

    const getExistingMenuItemOptionGroups = async (menu_item_id: string) => {
        const { data, error } = await supabaseClient
            .from('menu_item_option_groups')
            .select(
                `
      *,
      option_groups (
        *,
        option_groups_localization: option_groups_localization (
          *
        ),
        option_group_items: menu_items (
          *,
          option_items_localization: menu_items_localization (
            *
          )
        )
      )
    `
            )
            .eq('menu_item_id', menu_item_id)

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const searchOptionalGroups = async ({
        search_value,
        p_language_code,
        include_localization = true,
        include_menu_items = true,
    }) => {
        try {
            const { data: { session } } = await supabaseClient.auth.getSession();

            const queryParams = new URLSearchParams({
                search_value: search_value || '',
                language_code: p_language_code || '',
                include_localization: String(include_localization),
                include_menu_items: String(include_menu_items),
            });

            const response = await fetch(`/api/search-option-groups?${queryParams}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            const result = await response.json();

            if (result.error) {
                throw result.error;
            }

            return { data: result.data, error: null };
        } catch (error) {
            captureException(error);
            console.error('Error searching option groups:', error);
            return { data: null, error };
        }
    };

    const createOrUpdateOptionalGroup = async (
        data: Partial<ModifiedMappedGroupTWithMappedLocale>,
        selectedOptions: ModifiedExtendedOptionalItemT[],
        initialOptionalGroupState: ModifiedMappedGroupTWithMappedLocale,
        mode: ENUM_MODES
    ) => {
        try {
            const { option_groups_localization, option_group_items, ...restOfData } = data;

            let localizationsToUpsert = Object.entries(option_groups_localization).map(
                ([langCode, localization]) => ({
                    language_code: langCode,
                    name: localization.name,
                    instruction: localization.instruction,
                })
            );

            let optionItemsToDelete = [];
            let optionItemsToUpsert = [];

            if (mode === MODE_CONSTANTS.CREATE || mode === MODE_CONSTANTS.DUPLICATE) {
                optionItemsToUpsert = selectedOptions.map((option) => ({
                    optional_item_id: option.id,
                }));
            } else if (mode === MODE_CONSTANTS.EDIT) {
                const existingOptionIds = initialOptionalGroupState.option_group_items.map(
                    (option) => option.option_id || option.id
                );
                const newOptionIds = selectedOptions.map((option) => option.id);

                optionItemsToDelete = existingOptionIds.filter((id) => !newOptionIds.includes(id));
                const optionItemsToInsert = newOptionIds.filter((id) => !existingOptionIds.includes(id));

                optionItemsToUpsert = selectedOptions
                    .filter((option) => optionItemsToInsert.includes(option.id || option.optionItemId))
                    .map((option) => ({
                        optional_item_id: option.id || option.optionItemId,
                    }));
            }

            const { data: { session } } = await supabaseClient.auth.getSession();

            const response = await fetch('/api/create-or-update-optional-group', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    _rest_of_data: restOfData,
                    _localizations_to_upsert: localizationsToUpsert,
                    _option_items_to_delete: optionItemsToDelete,
                    _option_items_to_upsert: optionItemsToUpsert,
                }),
            });

            const result = await response.json();

            if (result.error) {
                throw result.error;
            }

            return { optionalGroupData: result.data, optionalGroupError: null };
        } catch (error) {
            captureException(error);
            console.error('Error in create_or_update_optional_group:', error);
            return { optionalGroupData: null, optionalGroupError: error };
        }
    };

    const deleteOptionGroup = async (id: string) => {
        const { error } = await supabaseClient.from('option_groups').delete().eq('id', id)

        if (error) {
            captureException(error)
        }

        return error
    }

    const createOrUpdateMenu = async (
        data: ExtendedMenusT,
        selectedCategories: MenuCategoryT[],
        mode: string
    ) => {
        try {
            const { created_at, updated_at, menu_categories, label, ...restOfData } = data;

            let categoriesToDelete: string[] = [];
            let categoriesToUpsert = selectedCategories.map((category) => ({
                category_id: category.id,
                order_number: category.order_number,
            }));

            if (mode === MODE_CONSTANTS.EDIT) {
                const existingCategoryIds: string[] = menu_categories.map(
                    (category) => category.category_id || category.id
                );
                const newCategoryIds: string[] = selectedCategories.map((category) => category.id);
                categoriesToDelete = existingCategoryIds.filter((id) => !newCategoryIds.includes(id));
            }

            const { data: { session } } = await supabaseClient.auth.getSession();

            const response = await fetch('/api/create-or-update-menu', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    _rest_of_data: restOfData,
                    _categories_to_delete: categoriesToDelete,
                    _categories_to_upsert: categoriesToUpsert,
                }),
            });

            const result = await response.json();

            if (result.error) {
                captureException(result.error);
                console.error('Error in create_or_update_menu:', result.error);
                return [null, result.error];
            }

            return [result.data, null];
        } catch (error) {
            captureException(error);
            console.error('Error in create_or_update_menu:', error);
            return [null, error];
        }
    };

    const getMenus = async () => {
        const { error, data } = await supabaseClient.from('menus').select(`
        *,
        menu_categories (
            *
        )
      `)

        if (error) {
            captureException(error)
        }

        return { menuData: data, menuError: error }
    }

    const deleteMenu = async (menuId: string) => {
        const { error } = await supabaseClient.from('menus').delete().eq('id', menuId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const getEvents = async () => {
        const { data, error } = await supabaseClient.from('events').select(`
          *,
          events_localization (
              *
          ),
          event_menus(
              *,
              menus(
                  *
              )
          )
      `)

        if (error) {
            captureException(error)
            console.error('Error fetching events:', error)
        }

        return { eventsData: data, eventError: error }
    }

    const createOrUpdateEvent = async (
        eventData: ModifiedMappedExtendedEventsT,
        eventLocalizations: TMappedLocalization,
        selectedMenus: ExtendedMenusT[],
        mode: string
    ) => {
        try {
            const { events_localization, event_menus, description, name, ...restOfData } = eventData;

            // Prepare localizations for upsert
            let localizationsToUpsert = Object.entries(eventLocalizations).map(
                ([langCode, localization]) => ({
                    language_code: langCode,
                    name: localization.name,
                    description: localization.description,
                })
            );

            let menusToDelete: string[] = [];
            let menusToUpsert: { menu_id: string }[] = [];

            if (mode === MODE_CONSTANTS.CREATE || mode === MODE_CONSTANTS.DUPLICATE) {
                // For CREATE or DUPLICATE mode, treat all menus as new
                menusToUpsert = selectedMenus.map((menu) => ({
                    menu_id: menu.menu_id || menu.id,
                }));
            } else if (mode === MODE_CONSTANTS.EDIT) {
                // Existing logic for EDIT mode
                const existingMenuIds = event_menus?.map((menu) => menu.menu_id) || [];
                const newMenuIds = selectedMenus.map((menu) => menu.menu_id || menu.id);

                menusToDelete = existingMenuIds.filter((id) => !newMenuIds.includes(id));
                const menusToInsert = newMenuIds.filter((id) => !existingMenuIds.includes(id));

                menusToUpsert = selectedMenus
                    .filter((menu) => menusToInsert.includes(menu.menu_id || menu.id))
                    .map((menu) => ({
                        menu_id: menu.menu_id || menu.id,
                    }));
            }

            const { data: { session } } = await supabaseClient.auth.getSession();

            const response = await fetch('/api/create-or-update-event', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    _rest_of_data: restOfData,
                    _localizations_to_upsert: localizationsToUpsert,
                    _menus_to_delete: menusToDelete,
                    _menus_to_upsert: menusToUpsert,
                }),
            });

            const result = await response.json();

            if (result.error) {
                captureException(result.error);
                console.error('Error in create_or_update_event:', result.error);
                return [null, result.error];
            }

            return [result.data, null];
        } catch (error) {
            captureException(error);
            console.error('Error in create_or_update_event:', error);
            return [null, error];
        }
    };

    const deleteEvent = async (eventId: string) => {
        const { error } = await supabaseClient.from('events').delete().eq('id', eventId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const createOrUpdateVenue = async (
        data: ExtendedVenueSettingsT,
        workingHours: MappedWorkingHoursT
    ) => {
        const {
            id,
            active_working_hours,
            working_hours,
            days_of_operation,
            starting_hours,
            ending_hours,
            ...restOfData
        } = data

        // Upsert main venue data
        let { error: venueError } = await supabaseClient
            .from('venue_settings')
            .upsert([{ ...restOfData, id }], { onConflict: 'id' })

            .single()

        if (venueError) {
            captureException(venueError)
            console.error('Error upserting venue:', venueError)
            return [null, venueError]
        }

        // Handle working hours
        const workingHoursToUpsert = Object.entries(workingHours).map(([dayOfWeek, hoursData]) => ({
            day_of_week: Number(dayOfWeek),
            starting_hours: hoursData.starting_hours,
            ending_hours: hoursData.ending_hours,
            is_active: hoursData.is_active,
            venue_id: id
        }))

        const { data: workingHoursData, error: workingHoursError } = await supabaseClient
            .from('working_hours')
            .upsert(workingHoursToUpsert, { onConflict: 'day_of_week,venue_id' })
            .select()

        if (workingHoursError) {
            captureException(workingHoursError)
            console.error('Error upserting working hours:', workingHoursError)
            return [null, workingHoursError]
        }

        return [
            {
                ...restOfData,
                workingHoursData,
            },
            null,
        ]
    }

    const searchOrdersHistory = async (
        column: string,
        value: string,
        pageIndex: number,
        pageSize: number,
        fromDate: string,
        toDate: string
    ) => {
        try {
            const { data: { session } } = await supabaseClient.auth.getSession();

            const queryParams = new URLSearchParams({
                column_name: column,
                search_value: value,
                from_date: fromDate,
                to_date: toDate,
                page_index: pageIndex.toString(),
                page_size: pageSize.toString()
            });

            const response = await fetch(`/api/search-orders-history?${queryParams}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            const result = await response.json();

            if (result.error) {
                captureException(result.error);
                return { orders: [], error: result.error, count: 0 };
            }

            return {
                orders: result.data[0]?.data || [],
                error: null,
                count: result.data[0]?.count || 0
            };
        } catch (error) {
            captureException(error);
            return { orders: [], error, count: 0 };
        }
    };

    const searchCurrentOrders = async (
        column: string,
        value: string,
        pageIndex: number,
        pageSize: number,
        fromDate: string,
        toDate: string
    ) => {
        try {
            const { data: { session } } = await supabaseClient.auth.getSession();

            const queryParams = new URLSearchParams({
                column_name: column,
                search_value: value,
                from_date: fromDate,
                to_date: toDate,
                page_index: pageIndex.toString(),
                page_size: pageSize.toString()
            });

            const response = await fetch(`/api/search-current-orders?${queryParams}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            const result = await response.json();

            if (result.error) {
                captureException(result.error);
                return { orders: [], error: result.error, count: 0 };
            }

            return {
                orders: result.data[0]?.data || [],
                error: null,
                count: result.data[0]?.count || 0
            };
        } catch (error) {
            captureException(error);
            return { orders: [], error, count: 0 };
        }
    };

    const getOrdersHistory = async (
        fromDate: string,
        toDate: string,
        pageIndex = 0,
        pageSize = 20
    ) => {
        const offset = pageIndex * pageSize
        let {
            data: orders,
            error,
            count,
        } = await supabaseClient
            .from('orders_history')
            .select('*', { count: 'exact' })
            .gte('created_at', fromDate)
            .lte('created_at', toDate)
            .range(offset, offset + pageSize - 1)
            .order('created_at', { ascending: false })

        if (error) {
            captureException(error)
        }

        return { orders, error, count }
    }
    const getCurrentOrders = async (pageIndex = 0, pageSize = 20) => {
        const offset = pageIndex * pageSize
        let {
            data: orders,
            error,
            count,
        } = await supabaseClient
            .from('orders')
            .select('*', { count: 'exact' }) // get the exact count of rows
            .range(offset, offset + pageSize - 1)
            .order('created_at', { ascending: false })

        if (error) {
            captureException(error)
        }

        return { orders, error, count }
    }

    const getHistoryOrdersByReceiptId = async (receiptId: string) => {
        let { data: orders, error } = await supabaseClient
            .from('orders_history')
            .select('*') // You can specify only the columns you need
            .eq('receipt_id', receiptId)

        if (error) {
            captureException(error)
        }

        return [orders, error]
    }
    const getOrdersByReceiptId = async (receiptId: string) => {
        let { data: orders, error } = await supabaseClient
            .from('orders')
            .select('*') // You can specify only the columns you need
            .eq('receipt_id', receiptId)

        if (error) {
            captureException(error)
        }

        return [orders, error]
    }

    const fetchOrderFromHistory = async (orderId: string) => {
        try {
            const { data: { session } } = await supabaseClient.auth.getSession();

            const response = await fetch(`/api/fetch-historical-order?order_id=${orderId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            const result = await response.json();

            if (result.error) {
                captureException(result.error);
                console.error('Error fetching historical order:', result.error);
                return { data: null, error: result.error };
            }

            return { data: result.data, error: null };
        } catch (error) {
            captureException(error);
            console.error('Error fetching historical order:', error);
            return { data: null, error };
        }
    }

    const getMultipleMenuItemsLocaleFromHistory = async (ids: string[], locale: string) => {
        const { data, error } = await supabaseClient
            .from('menu_items_localization_history')
            .select('*')
            .in('language_code', [locale, LANGUAGES.EN])
            .in('item_id', ids)

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const getOrderHistorySummaryData = async (
        startDate: string,
        endDate: string,
        includeGrowth: boolean,
        comparisonType: string
    ) => {
        try {
            const { data: { session } } = await supabaseClient.auth.getSession();

            const queryParams = new URLSearchParams({
                interval_start: startDate,
                interval_end: endDate,
                show_growth: String(includeGrowth),
                comparison_type: comparisonType
            });

            const response = await fetch(`/api/order-history-summary?${queryParams}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            const result = await response.json();

            if (result.error) {
                throw result.error;
            }

            return [result.data, null];
        } catch (error) {
            captureException(error);
            console.error('Error fetching order history summary:', error);
            return [null, error];
        }
    }

    const getCurrentOrderSummaryData = async (
        startDate: string,
        endDate: string,
        includeGrowth: boolean,
        comparisonType: string
    ) => {
        try {
            const { data: { session } } = await supabaseClient.auth.getSession();

            const queryParams = new URLSearchParams({
                interval_start: startDate,
                interval_end: endDate,
                show_growth: String(includeGrowth),
                comparison_type: comparisonType
            });

            const response = await fetch(`/api/current-order-summary?${queryParams}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            const result = await response.json();

            if (result.error) {
                throw result.error;
            }

            return [result.data, null];
        } catch (error) {
            captureException(error);
            console.error('Error fetching current order summary:', error);
            return [null, error];
        }
    }

    // Memoize the returned object
    const objectApi = {
        getUserProfiles,
        deleteUserProfile,
        createUserProfile,
        updateUserProfile,
        getFloorsLayout,
        createOrUpdateFloor,
        deleteFloor,
        getSectionsLayout,
        createOrUpdateSection,
        updateSection,
        bulkUpdateSections,
        deleteSection,
        getDefaultTablesLayout,
        deleteDefaultTable,
        updateDefaultTable,
        bulkUpdateDefaultTable,
        createDefaultTable,
        getAllCategories,
        handleImageUploadOrUpdate,
        downloadImage,
        uploadImage,
        updateImage,
        createOrUpdateCategory,
        deleteCategory,
        getAllMenuItems,
        createOrUpdateMenuItem,
        deleteMenuItem,
        searchMenuItemsStaff,
        getAllOptionGroups,
        getExistingMenuItemOptionGroups,
        searchOptionalGroups,
        createOrUpdateOptionalGroup,
        deleteOptionGroup,
        createOrUpdateMenu,
        getMenus,
        deleteMenu,
        getEvents, createOrUpdateEvent, deleteEvent, createOrUpdateVenue,
        searchCurrentOrders,
        searchOrdersHistory,
        getOrdersHistory,
        getCurrentOrders,
        fetchOrderFromHistory,
        getMultipleMenuItemsLocaleFromHistory,
        getHistoryOrdersByReceiptId,
        getOrdersByReceiptId,
        getOrderHistorySummaryData,
        getCurrentOrderSummaryData,
    }

    return objectApi
}

export default useAdminPanelServiceApi
