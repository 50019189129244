import { ENUM_MODES, ENUM_ORDER_STATUS_CONSTANTS, ENUM_STATUS_CONSTANTS } from '@/enums/Enums';
import { BookingPageState, ExtendedOrderT, ExtendedReservationT, ExtendedTableT, ExtendedVenueSettingsT, Full, ModalState, ModifiedExtendedMenuItemT, ModifiedExtendedReservationT, OrdersDashboardState, ReservationsDashboardState, TablePageState, TableT } from '@/types/globalTypes';

export const updateState = <T>(state: T, value: T | ((prev: T) => T)): T => {
    return typeof value === 'function' ? (value as (prev: T) => T)(state) : value;
};

export const getDefaultExtendedReservationStructure = (): ModifiedExtendedReservationT => {
    return {
        id: '',
        email: '',
        extra_information: '',
        name_of_the_person: '',
        date_of_reservation: '',
        people: 0,
        phone_number: '',
        status: '',
        is_temporary: false,
        is_walk_in: false,
        table: getDefaultTableStructure(),
        actions: '',
        is_static: false,
        agreed_to_terms: false,
        created_at: '',
        end_time: '',
        guest_id: '',
        start_time: '',
        waiter_responded: false,
        hasPendingOrder: false,
        pin_code: '',
        skip_auto_pin_renewal: false,
        table_id: '',
        venue_id: '',
        is_active: false
    }
}

// Type guard to check if table is of type TableT
export const isTable = (table: TableT | null | string): table is TableT => {
    // Check if 'table' is not null and not a string
    return table !== null && typeof table !== 'string'
}

export const getDefaultTableStructure = (): ExtendedTableT => {
    return {
        floor: '',
        id: '',
        styles: {},
        x_coordinate: 0,
        y_coordinate: 0,
        reservation: {
            id: '',
            is_active: false,
            email: '',
            extra_information: '',
            name_of_the_person: '',
            date_of_reservation: '',
            people: 0,
            phone_number: '',
            status: '',
            is_temporary: false,
            is_walk_in: false,
            actions: '',
            is_static: true,
            agreed_to_terms: false,
            created_at: '',
            end_time: '',
            guest_id: '',
            start_time: '',
            waiter_responded: false,
            hasPendingOrder: false,
            pin_code: '',
            skip_auto_pin_renewal: false,
            table_id: '',
            venue_id: ''
        },
        reserved: false,
        section: '',
        table_name: '',
        created_at: '',
        is_static: false,
        section_id: '',
        static_reservation_id: '',
        table_date: '',
        waiter: '',
        waiter_name: '',
        venue_id: '',
        max_capacity: 1,
        min_capacity: 1
    }
}

export const getDefaultVenueSettings = (): ExtendedVenueSettingsT => {
    return {
        address: '',
        allow_reservations: false,
        banner_url: null,
        capacity: null,
        currency: null,
        description: null,
        duration: null,
        email: null,
        facebook_link: null,
        id: '',
        initial_setup: null,
        instagram_link: null,
        languages: null,
        latitude: null,
        linkedin_link: null,
        logo_url: null,
        longitude: null,
        map_url: null,
        max_calendar_days: 30,
        name: '',
        online_payments: null,
        payment_methods: null,
        phone_number: null,
        reservation_lead_time: '00:00',
        skip_auto_pin_renewal: false,
        tiktok_link: null,
        venue_timezone: '',
        venue_type: null,
        website: null,
        youtube_link: null,
        working_hours: {
            0: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 0, venue_id: '' },
            1: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 1, venue_id: '' },
            2: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 2, venue_id: '' },
            3: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 3, venue_id: '' },
            4: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 4, venue_id: '' },
            5: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 5, venue_id: '' },
            6: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 6, venue_id: '' },
        },
        active_working_hours: {
            starting_hours: '00:00',
            ending_hours: '00:00',
            is_active: false,
            day_of_week: 0,
            venue_id: ''
        },
        starting_hours: '00:00',
        ending_hours: '00:00',
        days_of_operation: [],
    }
};

export const getInitialReservationState = (): ModifiedExtendedReservationT => {
    return {
        id: '',
        is_active: false,
        email: '',
        extra_information: '',
        name_of_the_person: '',
        date_of_reservation: '',
        people: 0,
        phone_number: '',
        status: '',
        is_temporary: false,
        is_walk_in: false,
        actions: '',
        is_static: false,
        agreed_to_terms: false,
        created_at: '',
        end_time: '',
        guest_id: '',
        start_time: '',
        waiter_responded: false,
        hasPendingOrder: false,
        skip_auto_pin_renewal: false,
        table_id: null,
        venue_id: ''
    }
}

export const getInitialOrderState = (): ExtendedOrderT => {
    return {
        reservation_id: '',
        order_items: [],
        waiter: '',
        guest_id: '',
        last_updated_by: '',
        order_number: 0,
        paid_online: false,
        receipt_id: '',
        receipt_number: 0,
        venue_id: '',
        reservation: getDefaultExtendedReservationStructure(),
        special_requests: '',
        total_items_quantity: 0,
        total_price: 0,
        waiter_name: '',
        id: '',
        status: '',
        created_at: '',
        updated_at: '',
    }
}

export const getInitialSelectedTableState = (): ExtendedTableT => {
    return {
        floor: '',
        id: '',
        styles: {},
        x_coordinate: 0,
        y_coordinate: 0,
        venue_id: '',
        reservation: getDefaultExtendedReservationStructure(),
        reserved: false,
        created_at: '',
        is_static: false,
        static_reservation_id: '',
        table_date: '',
        waiter: '',
        section: '',
        table_name: '',
        section_id: '',
        waiter_name: '',
        max_capacity: 1,
        min_capacity: 1
    }
}

export const getInitialDashboardStateState = (): OrdersDashboardState => {
    return {
        tablesData: [],
        orders: [],
        floors: [],
        sections: [],
        reservations: [],
        reservationAction: null,
        status: ENUM_ORDER_STATUS_CONSTANTS.NONE,
        loading: false,
        selectedMenuItems: [],
        selectedItemMode: ENUM_MODES.CREATE,
        orderMode: ENUM_MODES.CREATE,
        selectedOrderSingleItem: {
            id: '',
            menu_item_number: 0,
            venue_id: '',
            has_nutritional_data: false,
            is_active: true,
            is_optional: false,
            options: [],
            quantity: 0,
            unique_id: '',
            price: 0,
            image_url: '',
            created_at: '',
            updated_at: '',
            allergens: [],
            preparation_time: 0,
            availability: true,
            tags: [''],
            sizes: [''],
            promotion: 0,
            inventory: 0,
            maxinventory: 0,
            has_options: false,
            track_inventory: false,
            name: '',
        },
        searchedOrders: [],
        searchValue: '',
        initialPageLoad: true,
        offset: 0,
        isLoading: false,
        searchLoading: false,
        hasMore: true,
    }
};

export const getInitialReservationsDashboardState = (): ReservationsDashboardState => {
    return {
        tablesData: [],
        orders: [],
        floors: [],
        sections: [],
        reservations: [],
        status: ENUM_STATUS_CONSTANTS.PENDING,
        loading: false,
        initialPageLoad: true,
        selectedFlatRows: [],
        reservationsCountPerDay: {},
        events: [],
        selectedEvents: [],
        showTableComponent: false,
        lockCurrentShift: false,
        selectedDate: new Date(),
        dateToday: new Date(),
        minDate: new Date(),
        maxDate: new Date(),
        starting_hours: "",
        ending_hours: "",
        reservationAction: null
    }
};

export const getInitialBookingPageState = (): BookingPageState => {
    return {
        reservations: [],
        tablesData: [],
        floors: [],
        sections: [],
        events: [],
        selectedEvents: [],
        selectedTable: getInitialSelectedTableState(),
        selectedTimePreferences: null,
        loading: false,
        showTimePreferences: false,
        showFloorPlanMap: false,
        reservationSteps: 0,
        confirmedReservationPopup: false,
        initialPageLoad: true,
        selectedDate: null,
        dateToday: null,
        minDate: null,
        maxDate: null,
        ending_hours: '',
        starting_hours: '',
        reservationAction: null,
        availableTablesData: [],
        selectedReservation: getDefaultExtendedReservationStructure()
    }
}



export const getInitialModalState = (): ModalState => {
    return {
        openDisableStaticReservationPopup: false,
        openCustomerFloorPlanMapPopup: false,
        openReservationScheduler: false,
        openActivateSelectedReservationPopup: false,
        openDeactivateSelectedReservationPopup: false,
        openReservationActionsPopup: false,
        openPrintQrCodePopup: false,
        openViewTablePopup: false,
        checkOutStep: 0,
        openActionsPopup: false,
        openCancelActionPopup: false,
        openCheckoutPopup: false,
        openCompleteReservationPopup: false,
        paymentActionsPopup: false,
        openDeleteTablePopup: false,
        openEditTablePopup: false,
        openCopyDefaultTableLayoutPopup: false,
        openCreateTablePopup: false,
        openCancelReservationPopup: false,
        openViewPopup: false,
        openViewTableOrdersPopup: false,
        orderActionsPopup: false,
        selectedOrderPopup: false,
        createOrderPopup: false,
        removeItemPopup: false,
        menuAddonsPopup: false,
        isTableToBeReserved: false,
        isReservationToBeRelocated: false,
        openCancelOrderPopup: false,
        openCreateWalkInReservationPopup: false,
        openAssignReservationToTablePopup: false,
        openAssignWaiterToTablePopup: false,
        openUnassignWaiterToTablePopup: false,
        openConfirmActionPopup: false,
        openHandleAcceptReservationRequestPopup: false,
        openHandleDeclineReservationRequestPopup: false,
        openMoveReservationToAnotherTablePopup: false,
        openRemoveReservationFromTablePopup: false,
        openCompleteReservationFromTablePopup: false,
        openGenerateNewPinCode: false,
        openAutoRenewalPinCodePopup: false,
        openScanReservationPopup: false,
        openScanErrorPopup: false,
        openDrawer: false,
        openCreateFloorPopup: false,
        openEditFloorPopup: false,
        openDeleteFloorPopup: false,
        openCreateSectionPopup: false,
        openEditSectionPopup: false,
        openDeleteSectionPopup: false,
        selectedTable: null,
        orders: [],
        buttons: [],
        selectedOrder: null,
        selectedMenuItems: [],
        loading: false,
        profile: null,
        selectedOrderSingleItem: {} as ModifiedExtendedMenuItemT,
        selectedItemMode: ENUM_MODES.CREATE,
        openMenuPopup: false,
        orderMode: ENUM_MODES.CREATE,
        selectedReservation: null,
        singleOrderItemActionButtonsArray: [],
        venuePrefix: '',
        reserveTablePopupButtons: [],
        assignTableToWaiterButtons: [],
        unassignTableToWaiterButtons: [],
        acceptTableActionButtons: [],
        handleAcceptReservationRequestButtonsArray: [],
        handleDeclineReservationRequestButtonsArray: [],
        removeReservationPopupButtons: [],
        completeReservationPopupButtons: [],
        generateNewPinCodeButtons: [],
        openCreateReservationPopup: false,
        openDeleteReservationPopup: false,
        openEditReservationPopup: false,
        openEventsDetailPopup: false,
        openViewReservationPopup: false,
        scanErrorMessage: ''
    }

};



export const getDefaultSelectedOrderSingleItem = (): ModifiedExtendedMenuItemT => {
    return {
        id: '',
        has_nutritional_data: false,
        is_active: true,
        is_optional: false,
        options: [],
        quantity: 0,
        unique_id: '',
        price: 0,
        image_url: '',
        created_at: '',
        updated_at: '',
        allergens: [],
        preparation_time: 0,
        availability: true,
        tags: [''],
        sizes: [''],
        promotion: 0,
        inventory: 0,
        maxinventory: 0,
        has_options: false,
        track_inventory: false,
        name: '',
        menu_item_number: 0,
        venue_id: '',
    }
}

export const getInitialTablePageState = (): TablePageState => {
    return {
        reservation: getDefaultExtendedReservationStructure(),
        tableData: getDefaultTableStructure(),
        orders: [],
        floors: [],
        sections: [],
        selectedMenuItems: [],
        selectedItemMode: ENUM_MODES.CREATE,
        orderMode: ENUM_MODES.CREATE,
        selectedOrderSingleItem: getDefaultSelectedOrderSingleItem(),
        actionType: '',
        selectedOrder: getInitialOrderState(),
        loading: true
    }
}

export const isSameTable = (reservation: ExtendedReservationT, table: ExtendedTableT | TableT) =>
    reservation.table_id === table.id || reservation.id === table.static_reservation_id;

export const isActiveReservation = (reservation: ExtendedReservationT) =>
    reservation.is_active;

export const isStaticReservation = (reservation: ExtendedReservationT, table: ExtendedTableT | TableT) =>
    table.is_static && table.static_reservation_id === reservation.id;

export const findTableReservationData = (reservations: ExtendedReservationT[], table: ExtendedTableT | TableT) => {
    // 1. First priority: Active reservations
    const activeReservation = reservations.find(r =>
        isSameTable(r, table) && isActiveReservation(r)
    );

    if (activeReservation) return activeReservation;

    // 2. Second priority: Static reservations
    const staticReservation = reservations.find(r =>
        isStaticReservation(r, table)
    );
    if (staticReservation) return staticReservation;

    // 3. Third priority: Any other inactive reservations
    const inactiveReservation = reservations.find(r =>
        isSameTable(r, table) && !r.is_active
    );
    if (inactiveReservation) return inactiveReservation;

    return null;
};


/** Marks every property as required (non-optional). However property values can still be undefined. */
export function full<T>(x: T) {
    return x as Full<T>;
}
