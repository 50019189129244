import { TranslationModule } from '@/i18n';
import { ProfileT, ToastNotificationT } from '@/types/globalTypes';
import { User } from '@supabase/supabase-js';
import { CustomLens } from '../store';

export type ApplicationStateSlice = {
    translations: TranslationModule,
    profile: ProfileT,
    user: User,
    venueId: string,
    selectedLanguage: string,
    setToastNotification: React.Dispatch<React.SetStateAction<ToastNotificationT>>,
};

export const applicationStateSlice: CustomLens<ApplicationStateSlice> = (set, get, api, context, setGlobalState, getGlobalState, initialState) => {

    return {
        ...initialState.applicationState
    }
}
