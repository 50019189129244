/* eslint-disable react-hooks/rules-of-hooks */
import ordersDashboardReducer from '../reducers/ordersDashboardReducer'
import { Setter } from '@dhmk/zustand-lens'
import { v4 as uuidv4 } from 'uuid'

import {
    ApplicationAction,
    ExtendedOrderT,
    ExtendedReservationT,
    ExtendedTableT,
    ModifiedExtendedMenuItemT,
    ModifiedExtendedReservationT,
    OrdersDashboardState,
    OrderT,
    ReservationSubmitValuesТ,
    ReservationT,
    ToastNotificationT,
} from '@/types/globalTypes'
import { ENUM_ACTION_TYPES, ENUM_MODES, ENUM_ORDER_STATUS_CONSTANTS, ENUM_RESERVATION_ACTIONS } from '@/enums/Enums'
import { getInitialDashboardStateState, getInitialOrderState, getInitialReservationState, getInitialSelectedTableState, updateState } from '@/utils/stateUtils'

import { ENUM_ACTIONS, NOTIFICATION_TYPE, ORDER_STATUS_CONSTANTS, STATUS_CONSTANTS } from '@/constants/constants'

import { CustomLens } from '../store'

import { calculateTotalItemsQuantity, mapSelectedItems } from '@/utils/functionUtils'
import { vanillaTRPC as trpc } from '@/utils/trpc'

export type OrdersSlice = {
    state: OrdersDashboardState
    dispatch: (action: ApplicationAction) => void
    selectedReservation: ModifiedExtendedReservationT
    initialReservationState: ModifiedExtendedReservationT
    initialSelectedTableState: ExtendedTableT
    initialOrderState: ExtendedOrderT
    selectedOrder: ExtendedOrderT
    selectedTable: ExtendedTableT
    setSelectedReservation: Setter<ModifiedExtendedReservationT>
    setSelectedOrder: Setter<ExtendedOrderT>
    setSelectedTable: Setter<ExtendedTableT>
    setStatus: Setter<ENUM_ORDER_STATUS_CONSTANTS>
    setLoading: Setter<boolean>
    setSelectedMenuItems: Setter<ModifiedExtendedMenuItemT[]>
    setSelectedItemMode: Setter<string>
    setOrderMode: Setter<ENUM_MODES>
    setSelectedOrderSingleItem: Setter<ModifiedExtendedMenuItemT>
    setSearchedOrders: Setter<OrderT[]>
    setSearchValue: Setter<string>
    setInitialPageLoad: Setter<boolean>
    setOffset: Setter<number>
    setIsLoading: Setter<boolean>
    setSearchLoading: Setter<boolean>
    setHasMore: Setter<boolean>
    setReservationAction: Setter<ENUM_RESERVATION_ACTIONS | null>
    setToastNotification: React.Dispatch<React.SetStateAction<ToastNotificationT>>,
    handleGenerateNewPinCode: (static_reservation_id: string) => Promise<void>
    handleAcceptReservationRequest: (reservation: ExtendedReservationT) => Promise<void>
    handleDeclineReservationRequest: (reservation: ExtendedReservationT) => Promise<void>
    handleCreateWalkInReservation: (reservation: ExtendedReservationT) => Promise<void>
    handleReserveTable: (reservation: ExtendedReservationT, tableId: string) => Promise<void>
    handleUnassignTableToWaiter: (table: ExtendedTableT) => Promise<void>
    handleAssignTableToWaiter: (waiterId: string, waiterName: string, table: ExtendedTableT) => Promise<void>
    handleCompleteReservation: () => Promise<void>
    handleUpdateReservation: (values: ReservationSubmitValuesТ) => Promise<void>
    handleCancelReservation: (reservation: ModifiedExtendedReservationT) => Promise<void>;
    handleConfirmReservation: (reservation: ReservationSubmitValuesТ) => Promise<void>;
    handleDisableOrEnableAutoPinRenewal: (value: boolean) => Promise<void>
    handleAcceptOrder: () => Promise<void>
    handleUpdateOrder: (
        newOrder: ExtendedOrderT,
        initialOrderItemsState?: ModifiedExtendedMenuItemT[],
        updateOrderItems?: boolean
    ) => Promise<void>
    handleCreateOrder: (
        selectedMenuItems: ModifiedExtendedMenuItemT[],
        totalPrice: number,
        specialRequests: string
    ) => Promise<void>
    handleBulkUpdateReservation: (reservations: Partial<ExtendedReservationT>[]) => Promise<void>
    handleReservationActionsPopup: (resetReservation: boolean) => void
    onCancelActionType: () => void
}


export const ordersDashboardSlice: CustomLens<OrdersSlice> = (set, get, api, ctx, setGlobalState, getGlobalState) => {

    // Helper functions
    const getToastTexts = () => getGlobalState().applicationState.translations?.TOAST_NOTIFICATIONS_TEXT || {
        GENERIC_ERROR_MESSAGE: 'Error',
        GENERIC_SUCCESS_MESSAGE: 'Success'
    };

    const showErrorToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.ERROR,
            message: customMessage || toastTexts.GENERIC_ERROR_MESSAGE,
        });
    };

    const showSuccessToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: customMessage || toastTexts.GENERIC_SUCCESS_MESSAGE,
        });
    };


    const setToastNotification = (notification: ToastNotificationT) => getGlobalState().applicationState.setToastNotification(notification);


    const createSetter = <K extends keyof OrdersSlice>(key: K) => (value: OrdersSlice[K]) => set((state: OrdersSlice) => ({
        ...state,
        [key]: updateState(state[key], value),
    }));

    const createNestedSetter = <K extends keyof OrdersDashboardState>(key: K) => (value: OrdersDashboardState[K]) => set((state: OrdersSlice) => ({
        ...state,
        state: {
            ...state.state,
            [key]: updateState(state.state[key], value),
        },
    }));


    const setLoading = (loading: boolean) => {
        set((state) => ({
            state: { ...state.state, loading }
        }));
    };


    const dispatch = (action: ApplicationAction) =>
        set((state) => ({
            ...state,
            state: ordersDashboardReducer(state.state, action),
        }))

    const resetSelectedTableAndReservation = (isReservationToBeRelocated = false) => {

        set((state) => ({
            selectedTable: state.initialSelectedTableState,

        }));

        if (isReservationToBeRelocated === false) {
            set((state) => ({
                selectedReservation: state.initialReservationState,
            }));

        }
    }

    const onCancelActionType = () => {
        get().setReservationAction(null)
        resetSelectedTableAndReservation()
        closeAllPopups()
    }

    const closeAllPopups = () => {
        getGlobalState().modalState.setOpenDisableStaticReservationPopup(false)
        getGlobalState().modalState.setOpenDrawer(false)
        getGlobalState().modalState.setOpenAssignReservationToTablePopup(false)
        getGlobalState().modalState.setOpenMoveReservationToAnotherTablePopup(false)
        getGlobalState().modalState.setIsReservationToBeRelocated(false)
        getGlobalState().modalState.setOpenViewPopup(false)
        getGlobalState().modalState.setOpenRemoveReservationFromTablePopup(false)
        getGlobalState().modalState.setIsTableToBeReserved(false)
        get().setReservationAction(null)
        getGlobalState().modalState.setOpenActivateSelectedReservationPopup(false);
        getGlobalState().modalState.setOpenDeactivateSelectedReservationPopup(false);
        getGlobalState().modalState.setReservationActionsPopup(false);
        getGlobalState().modalState.setOpenCancelReservationPopup(false);
        getGlobalState().modalState.setOpenCompleteReservationFromTablePopup(false);
    }

    const handleReservationActionsPopup = (resetReservation = true) => {
        getGlobalState().modalState.setReservationActionsPopup(false)
        if (resetReservation) {
            setTimeout(() => {
                get().setSelectedReservation(get().initialReservationState)
            }, 200)
        }

    }
    const handleBulkUpdateReservation = async (reservations: ExtendedReservationT[]) => {
        try {
            setLoading(true);
            const { data } = await trpc.reservations.bulkUpdate.mutate(reservations);

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATIONS,
                payload: { updatedReservations: data as ReservationT[] },
            });


            closeAllPopups();
            showSuccessToast();
        } catch (error) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleDisableOrEnableAutoPinRenewal = async (value: boolean) => {


        try {
            setLoading(true);
            const selectedTable = get().selectedTable;

            const reservation = selectedTable.reservation;

            await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    id: reservation.id,
                    skip_auto_pin_renewal: value,
                }
            })

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: { ...reservation, skip_auto_pin_renewal: value } },
            });

            set((state) => ({
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        skip_auto_pin_renewal: value,
                    },
                },
            }));
            setLoading(false);
            showSuccessToast();
            getGlobalState().modalState.setOpenAutoRenewalPinCodePopup(false);
        } catch (err) {
            showErrorToast();
            setLoading(false);
        }


    };

    const handleGenerateNewPinCode = async (static_reservation_id: string) => {
        setLoading(true);

        try {
            const { data } = await trpc.pinCodes.generateNew.mutate({
                static_reservation_id
            });

            set((state) => ({
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        id: state.selectedTable.static_reservation_id,
                        pin_code: data.new_pin_code,
                        guest_id: data.new_guest_id,
                    },
                },
            }));

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: {
                    updatedReservation: {
                        ...get().selectedReservation,
                        pin_code: data.new_pin_code,
                        guest_id: data.new_guest_id,
                    }
                },
            });

            showSuccessToast();
            getGlobalState().modalState.setOpenGenerateNewPinCode(false);
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleAcceptReservationRequest = async (reservation: ExtendedReservationT) => {
        setLoading(true);
        try {
            await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    id: reservation.id,
                    actions: ENUM_ACTIONS.REQUEST_ACCEPTED,
                    waiter_responded: true,
                }
            });

            set((state) => ({
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        actions: '',
                    },
                },
            }));

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: {
                    updatedReservation: {
                        ...reservation,
                        actions: '',
                        waiter_responded: true,
                    },
                },
            });

            getGlobalState().modalState.setOpenHandleAcceptReservationRequestPopup(false);
            getGlobalState().modalState.setOpenConfirmActionPopup(false);
            showSuccessToast();
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleDeclineReservationRequest = async (reservation: ExtendedReservationT) => {
        setLoading(true);
        try {
            await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    id: reservation.id,
                    actions: ENUM_ACTIONS.REQUEST_DECLINED,
                    waiter_responded: true,
                }
            });

            set((state) => ({
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        actions: '',
                    },
                },
            }));

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: {
                    updatedReservation: {
                        ...reservation,
                        actions: '',
                        waiter_responded: true,
                    },
                },
            });

            getGlobalState().modalState.setOpenHandleDeclineReservationRequestPopup(false);
            getGlobalState().modalState.setOpenConfirmActionPopup(false);
            showSuccessToast();
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleCancelReservation = async (reservation: ModifiedExtendedReservationT) => {
        try {
            setLoading(true);
            const data = await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    status: STATUS_CONSTANTS.DECLINED,
                    is_active: false,
                }
            });

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: data as ReservationT },
            });

            getGlobalState().modalState.setOpenCancelReservationPopup(false);
            getGlobalState().modalState.setReservationActionsPopup(false);
            showSuccessToast();
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmReservation = async (reservation: ReservationSubmitValuesТ) => {
        try {
            setLoading(true);
            const data = await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    status: STATUS_CONSTANTS.CONFIRMED,
                    is_active: true,
                }
            });

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: data as ReservationT },
            });

            getGlobalState().modalState.setOpenEditReservationPopup(false);
            getGlobalState().modalState.setOpenViewReservationPopup(false);
            showSuccessToast();
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleCompleteReservation = async () => {
        setLoading(true);

        try {
            const data = await trpc.reservations.update.mutate({
                id: get().selectedReservation.id,
                data: {
                    id: get().selectedReservation.id,
                    status: STATUS_CONSTANTS.COMPLETED,
                    waiter_responded: true,
                    table_id: null
                }
            });


            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: data as ReservationT },
            });

            getGlobalState().modalState.setOpenCompleteReservationFromTablePopup(false);
            getGlobalState().modalState.setOpenViewPopup(false);
            resetSelectedTableAndReservation();

            showSuccessToast();
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleReserveTable = async (reservation: ExtendedReservationT, tableId: string) => {
        try {

            const updatedReservation = {
                id: reservation.id,
                people: reservation.people,
                extra_information: reservation.extra_information,
                start_time: reservation.start_time,
                end_time: reservation.end_time,
                table_id: tableId,
            };

            setLoading(true);

            await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    id: reservation.id,
                    table_id: tableId,
                },
            })

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: { ...reservation, ...updatedReservation } },
            });

            get().setReservationAction(null)
            resetSelectedTableAndReservation();
            closeAllPopups();
            setLoading(false);
        } catch (error) {
            showErrorToast();
            setLoading(false);
        }
    };

    const handleAssignTableToWaiter = async (waiterId: string, waiterName: string, table: ExtendedTableT) => {
        setLoading(true);
        try {
            // Assign waiter to table
            await trpc.tables.assignWaiter.mutate({
                tableId: table.id,
                waiterId,
                waiterName
            });

            // Update orders if needed
            const orderIdsForTable = getTableOrders(table);

            if (orderIdsForTable.length > 0) {

                const { data } = await trpc.orders.updateMultiple.mutate({ ids: orderIdsForTable, data: { waiter: waiterId, waiter_name: waiterName } });

                dispatch({
                    type: ENUM_ACTION_TYPES.UPDATE_ORDERS,
                    payload: { updatedOrders: data as OrderT[] },
                });
            }

            dispatch({
                type: ENUM_ACTION_TYPES.ASSIGN_TABLE_TO_WAITER,
                payload: {
                    waiterId,
                    table,
                    waiterName,
                    orderIdsForTable,
                },
            });

            if(get().selectedTable.id === table.id) {
                set((state) => ({
                    selectedTable: { ...state.selectedTable, waiter: waiterId, waiter_name: waiterName },
                }));
            }

            getGlobalState().modalState.setOpenAssignWaiterToTablePopup(false);

            showSuccessToast();
        } catch (error) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleUnassignTableToWaiter = async (table: ExtendedTableT) => {
        setLoading(true);
        try {
            // Unassign waiter from table
            await trpc.tables.unassignWaiter.mutate(table.id);

            // Update orders if needed
            const orderIdsForTable = getTableOrders(table);

            if (orderIdsForTable.length > 0) {
                await trpc.orders.updateMultiple.mutate({ ids: orderIdsForTable, data: { waiter: null, waiter_name: null } });
            }

            dispatch({
                type: ENUM_ACTION_TYPES.ASSIGN_TABLE_TO_WAITER,
                payload: {
                    waiterId: null,
                    table,
                    waiterName: null,
                    orderIdsForTable,
                },
            });
            if(get().selectedTable.id === table.id) {
                set((state) => ({
                    selectedTable: { ...state.selectedTable, waiter: null, waiter_name: null },
                }));
            }
            getGlobalState().modalState.setOpenUnassignWaiterToTablePopup(false);
            showSuccessToast();
        } catch (error) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    // Helper function to get table orders
    const getTableOrders = (table: ExtendedTableT) => {
        const excludeOrderByStatus = [
            ORDER_STATUS_CONSTANTS.PAID,
            ORDER_STATUS_CONSTANTS.SERVED,
            ORDER_STATUS_CONSTANTS.REFUNDED,
        ];

        return get().state.orders
            .filter(order =>
                (order.reservation.table?.id === table.id ||
                    order.reservation.id === table.static_reservation_id) &&
                !excludeOrderByStatus.includes(order.status)
            )
            .map(order => order.id);
    };

    const handleUpdateReservation = async (values: ReservationSubmitValuesТ) => {
        setLoading(true);

        try {
            const data = await trpc.reservations.update.mutate({
                id: values.id,
                data: {
                    id: values.id,
                    name_of_the_person: values.fullName,
                    email: values.email,
                    phone_number: values.phone,
                    date_of_reservation: values.date_of_reservation,
                    people: values.people,
                    status: values.status,
                    extra_information: values.extra_information,
                    start_time: values.start_time,
                    end_time: values.end_time,
                    is_active: values.is_active,
                }
            });

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: data as ReservationT },
            });
            getGlobalState().modalState.setOpenEditReservationPopup(false);
            getGlobalState().modalState.setReservationActionsPopup(false);
            getGlobalState().modalState.setOpenDeactivateSelectedReservationPopup(false);
            showSuccessToast();
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleCreateWalkInReservation = async (values: ExtendedReservationT) => {
        try {
            setLoading(true);
            const reservationData = {
                date_of_reservation: values.date_of_reservation,
                people: values.people,
                status: STATUS_CONSTANTS.CONFIRMED,
                extra_information: values.extra_information,
                start_time: values.start_time,
                end_time: values.end_time,
                is_temporary: true,
                is_walk_in: true,
                venue_id: values.venue_id,
                table_id: get().selectedTable.id
            } as ReservationT;

            const { data } = await trpc.reservations.create.mutate(reservationData);

            dispatch({
                type: ENUM_ACTION_TYPES.INSERT_RESERVATION,
                payload: { newReservation: data as ReservationT },
            });

            if (get().selectedTable.id !== '') {
                set((state) => ({
                    selectedTable: { ...state.selectedTable, reservation: data as ReservationT },
                }));
            }

            getGlobalState().modalState.setOpenCreateWalkInReservationPopup(false);
            showSuccessToast();
        } catch (error) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleCreateOrder = async (
        selectedMenuItems: ModifiedExtendedMenuItemT[],
        totalPrice: number,
        specialRequests: string
    ) => {

        try {
            setLoading(true);

            const profile = getGlobalState().applicationState.profile || { id: '', full_name: 'Unknown', venue_id: '' };
            const selectedTable = get().selectedTable;
            let guestID = selectedTable.reservation.guest_id || selectedTable.reservation.id;

            const orderItems = mapSelectedItems(selectedMenuItems);
            const mappedData = {
                total_price: totalPrice,
                status: ORDER_STATUS_CONSTANTS.ACCEPTED,
                reservation_id: selectedTable.reservation.id || selectedTable.static_reservation_id,
                waiter: profile.id,
                waiter_name: profile.full_name,
                special_requests: specialRequests,
                guest_id: guestID,
                venue_id: profile.venue_id,
                total_items_quantity: calculateTotalItemsQuantity(selectedMenuItems),
                last_updated_by: profile.id,
            }

            const preparedOrderItems = orderItems.map((item) => ({
                ...item,
                menu_item_id: item.id,
                quantity: item.quantity,
                price: item.price,
                unique_id: item.unique_id,
                promotion: item.promotion,

            }));

            // Prepare order item options
            const orderItemOptions = orderItems.flatMap(
                (item) =>
                    item.options.map((option) => ({
                        ...option,

                        unique_option_item_id: uuidv4(),
                        option_item_id: option.id,
                        option_group_id: option.option_group_id,
                        quantity: option.quantity,
                        price: option.price,
                        promotion: option.promotion,
                        optional_menu_items_group_id: option.optional_menu_items_group_id,
                        unique_menu_item_id: item.unique_id,
                    }))
            );


            const { data } = await trpc.orders.create.mutate({
                _order_details: mappedData,
                _order_items: preparedOrderItems,
                _order_item_options: orderItemOptions,
            });


            getGlobalState().modalState.setCreateOrderPopup(false);
            getGlobalState().modalState.setOpenMenuPopup(false);
            getGlobalState().modalState.setOrderActionsPopup(false);
            set((state) => ({
                state: {
                    ...state.state,
                    selectedMenuItems: [],
                },
            }));
            const { ...rest } = mappedData

            dispatch({
                type: ENUM_ACTION_TYPES.INSERT_ORDER,
                payload: {
                    newOrder: {
                        ...rest,
                        id: data.returned_order_id,
                        order_number: data.returned_order_number,
                        created_at: data.returned_created_at,
                        total_price: data.returned_total_price
                    } as OrderT,
                },
            });
            showSuccessToast();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            showErrorToast();
        }
    };

    const handleUpdateOrder = async (
        newOrder: ExtendedOrderT,
        initialOrderItemsState: ModifiedExtendedMenuItemT[] = null,
        updateOrderItems = false
    ) => {
        try {

            setLoading(true);
            const profile = getGlobalState().applicationState.profile || { id: '', full_name: 'Unknown' };

            const { reservation, id: orderId, order_items, buttons, ...restOfOrder } = newOrder;

            // Preparing the order details for the update
            const orderDetails = {
                ...restOfOrder,
                last_updated_by: profile.id,
                id: orderId,
            };
            let updatedOrder: OrderT;
            if (updateOrderItems) {

                // Preparing the order item options for the update
                const initialOptionItemsState = initialOrderItemsState?.flatMap((item) =>
                    item.options.map((option) => ({
                        unique_order_id: orderId,
                        unique_option_item_id: option.unique_option_item_id,
                        option_item_id: option.id || option.option_item_id,
                        option_group_id: option.option_group_id || option.optionGroupId,
                        quantity: option.quantity,
                        price: option.price,
                        optional_menu_items_group_id: option.optional_menu_items_group_id,
                        unique_menu_item_id: item.unique_id,
                    }))
                ) || [];

                const orderItemOptions = order_items.flatMap((item) =>
                    item.options.map((option) => ({
                        ...option,
                        unique_order_id: orderId,
                        unique_option_item_id: option.unique_option_item_id || uuidv4(),
                        option_item_id: option.id || option.option_item_id,
                        option_group_id: option.option_group_id || option.optionGroupId,
                        quantity: option.quantity,
                        price: option.price,
                        optional_menu_items_group_id: option.optional_menu_items_group_id,
                        unique_menu_item_id: item.unique_id,
                    }))
                );


                const result = await trpc.orders.updateOrderAndItems.mutate(
                    {
                        _order_id: orderId,
                        _order_details: orderDetails,
                        _order_items: order_items,
                        _order_item_options: orderItemOptions,
                        _initial_order_items_state: initialOrderItemsState,
                        _initial_option_items_state: initialOptionItemsState,
                    },

                );
                updatedOrder = result.data.updated_order as OrderT;
            } else {
                const { data } = await trpc.orders.updateOrder.mutate({
                    id: orderDetails.id,
                    data: orderDetails
                });
                updatedOrder = data[0] as unknown as OrderT;
            }
            getGlobalState().modalState.setOpenCancelActionPopup(false);
            getGlobalState().modalState.setSelectedOrderPopup(false);
            getGlobalState().modalState.setOrderActionsPopup(false);


            set((state) => ({
                state: {
                    ...state.state,
                    selectedMenuItems: [],
                    selectedItemMode: ENUM_MODES.CREATE,
                    orderMode: ENUM_MODES.CREATE,
                },
            }));

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_ORDER,
                payload: {
                    updatedOrder: updatedOrder,
                },
            });

            setLoading(false);
        } catch (error) {
            showErrorToast();
            setLoading(false);
        }
    };

    const handleAcceptOrder = async () => {
        try {
            const profile = getGlobalState().applicationState.profile || { id: '', full_name: 'Unknown' };
            const selectedTable = get().selectedTable;
            const selectedOrder = get().selectedOrder;


            if (selectedTable.waiter === null) {
                await get().handleAssignTableToWaiter(profile.id, profile.full_name, selectedTable);
                set((state) => ({
                    selectedTable: {
                        ...state.selectedTable,
                        waiter: profile.id,
                        waiter_name: profile.full_name,
                    },
                }));

            }
            await handleUpdateOrder({
                ...selectedOrder,
                id: selectedOrder.id,
                status: ORDER_STATUS_CONSTANTS.ACCEPTED,
                waiter: profile.id,
                waiter_name: profile.full_name,
                last_updated_by: profile.id,
            });
        } catch (error) {
            showErrorToast();
        }
    };

    return {
        state: getInitialDashboardStateState(),
        selectedReservation: getInitialReservationState(),
        selectedTable: getInitialSelectedTableState(),
        initialSelectedTableState: getInitialSelectedTableState(),
        selectedOrder: getInitialOrderState(),
        initialReservationState: getInitialReservationState(),
        initialOrderState: getInitialOrderState(),
        setToastNotification: () => { },
        dispatch: dispatch,
        setSelectedReservation: createSetter('selectedReservation'),
        setSelectedOrder: createSetter('selectedOrder'),
        setSelectedTable: createSetter('selectedTable'),
        setStatus: createNestedSetter('status'),
        setLoading: createNestedSetter('loading'),
        setSelectedMenuItems: createNestedSetter('selectedMenuItems'),
        setSelectedItemMode: createNestedSetter('selectedItemMode'),
        setOrderMode: createNestedSetter('orderMode'),
        setSelectedOrderSingleItem: createNestedSetter('selectedOrderSingleItem'),
        setSearchedOrders: createNestedSetter('searchedOrders'),
        setSearchValue: createNestedSetter('searchValue'),
        setInitialPageLoad: createNestedSetter('initialPageLoad'),
        setOffset: createNestedSetter('offset'),
        setIsLoading: createNestedSetter('isLoading'),
        setSearchLoading: createNestedSetter('searchLoading'),
        setReservationAction: createNestedSetter('reservationAction'),
        setHasMore: createNestedSetter('hasMore'),
        handleAcceptReservationRequest,
        handleAssignTableToWaiter,
        handleUnassignTableToWaiter,
        handleDeclineReservationRequest,
        handleGenerateNewPinCode,
        handleCompleteReservation,
        handleUpdateReservation,
        handleCancelReservation,
        handleConfirmReservation,
        handleCreateWalkInReservation,
        handleReserveTable,
        handleUpdateOrder,
        handleAcceptOrder,
        handleCreateOrder,
        handleDisableOrEnableAutoPinRenewal,
        handleBulkUpdateReservation,
        handleReservationActionsPopup,
        onCancelActionType
    }

}
