/* eslint-disable react-hooks/rules-of-hooks */
import tablePageReducer from '../reducers/tablePageReducer';
import { Setter } from '@dhmk/zustand-lens';

import {
    ApplicationAction,
    ExtendedOrderT,
    ExtendedReservationT,
    ModifiedExtendedMenuItemT,
    OrderT,
    TablePageState,
    ToastNotificationT,
} from '@/types/globalTypes';
import { CustomLens } from '../store';
import { ENUM_ACTION_TYPES, ENUM_MODES } from '@/enums/Enums';
import { calculateTotalItemsQuantity, mapSelectedItems } from '@/utils/functionUtils';
import { NOTIFICATION_TYPE, ORDER_STATUS_CONSTANTS, STATUS_CONSTANTS } from '@/constants/constants';

import { getInitialOrderState, getInitialTablePageState, updateState } from '@/utils/stateUtils';
import { vanillaTRPC as trpc } from '@/utils/trpc'


export type TTablePageSlice = {
    state: TablePageState;
    initialOrderState: ExtendedOrderT
    dispatch: (action: ApplicationAction) => void;
    setLoading: Setter<boolean>;
    setToastNotification: React.Dispatch<React.SetStateAction<ToastNotificationT>>;
    handleCreateOrder: (
        selectedMenuItems: ModifiedExtendedMenuItemT[],
        totalPrice: number,
        specialRequests: string
    ) => Promise<void>;
    handleUpdateOrder: (
        newOrder: ExtendedOrderT,
        initialOrderItemsState?: ModifiedExtendedMenuItemT[],
        updateOrderItems?: boolean
    ) => Promise<void>;
    handleConfirmAction: (actionType: string) => Promise<void>;
    handleCompleteReservation: () => Promise<void>;
    handleViewItemWithOptions: (item: ModifiedExtendedMenuItemT, mode?: ENUM_MODES) => void;
    handleCloseMenuItemsPopup: (open: boolean) => void;
    setSelectedMenuItems: Setter<ModifiedExtendedMenuItemT[]>;
    setSelectedItemMode: Setter<string>;
    setOrderMode: Setter<ENUM_MODES>;
    setSelectedOrderSingleItem: Setter<ModifiedExtendedMenuItemT>;
    setActionType: Setter<string>;
    setSelectedOrder: Setter<ExtendedOrderT>;
};



export const tablePageSlice: CustomLens<TTablePageSlice> = (set, get, api, ctx, setGlobalState, getGlobalState) => {


    const createNestedSetter = <K extends keyof TablePageState>(key: K) => (value: TablePageState[K]) => set((state: TTablePageSlice) => ({
        ...state,
        state: {
            ...state.state,
            [key]: updateState(state.state[key], value),
        },
    }));

    const setLoading = (loading: boolean) => {
        set((state) => ({
            state: { ...state.state, loading }
        }));
    };

    const setToastNotification = (notification: ToastNotificationT) => getGlobalState().applicationState.setToastNotification(notification);

    const getToastTexts = () => getGlobalState().applicationState.translations?.TOAST_NOTIFICATIONS_TEXT || {
        GENERIC_ERROR_MESSAGE: 'Error',
        GENERIC_SUCCESS_MESSAGE: 'Success'
    };

    const showErrorToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.ERROR,
            message: customMessage || toastTexts.GENERIC_ERROR_MESSAGE,
        });
    };

    const showSuccessToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: customMessage || toastTexts.GENERIC_SUCCESS_MESSAGE,
        });
    };


    const dispatch = (action: ApplicationAction) =>
        set((state) => ({
            ...state,
            state: tablePageReducer(state.state, action),
        }));

    const handleCreateOrder = async (
        selectedMenuItems: ModifiedExtendedMenuItemT[],
        totalPrice: number,
        specialRequests: string
    ) => {
        try {
            setLoading(true);

            const orderItems = mapSelectedItems(selectedMenuItems);
            const guestID: string = getGlobalState().applicationState.user?.app_metadata.guest_id || null;

            const venueId = getGlobalState().applicationState.venueId
            const reservation = get().state.reservation;
            const mappedData = {
                order_items: orderItems,
                total_price: totalPrice,
                status: ORDER_STATUS_CONSTANTS.PENDING,
                reservation_id: reservation.id,
                waiter: null,
                waiter_name: null,
                special_requests: specialRequests,
                guest_id: guestID,
                venue_id: venueId,
                last_updated_by: null,
                total_items_quantity: calculateTotalItemsQuantity(selectedMenuItems),
            } as ExtendedOrderT;

            const preparedOrderItems = orderItems.map((item) => ({
                ...item,
                menu_item_id: item.id,
                quantity: item.quantity,
                price: item.price,
                unique_id: item.unique_id,
                promotion: item.promotion,

            }));

            // Prepare order item options
            const orderItemOptions = orderItems.flatMap(
                (item) =>
                    item.options.map((option) => ({
                        ...option,
                        unique_option_item_id: crypto.randomUUID(),
                        option_item_id: option.id,
                        option_group_id: option.option_group_id,
                        quantity: option.quantity,
                        price: option.price,
                        promotion: option.promotion,
                        optional_menu_items_group_id: option.optional_menu_items_group_id,
                        unique_menu_item_id: item.unique_id,
                    }))
            );

            const { data: {
                returned_order_id,
                returned_created_at,
                returned_order_number
            } } = await trpc.orders.create.mutate({
                _order_details: mappedData,
                _order_items: preparedOrderItems,
                _order_item_options: orderItemOptions,
            });


            setGlobalState((state) => ({
                modalState: {
                    ...state.modalState,
                    state: {
                        ...state.modalState.state,
                        createOrderPopup: false,
                        openMenuPopup: false,
                    },
                },
            }));
            set((state) => ({
                state: {
                    ...state.state,
                    selectedMenuItems: [],
                },
            }));
            dispatch({
                type: ENUM_ACTION_TYPES.ADD_ORDER,
                payload: {
                    newOrder: {
                        ...mappedData,
                        created_at: returned_created_at,
                        order_number: returned_order_number,
                        id: returned_order_id
                    }
                },
            });
            setLoading(false);
        } catch (error) {
            showErrorToast();
            setLoading(false);
        }
    };

    const handleUpdateOrder = async (
        newOrder: ExtendedOrderT,
        initialOrderItemsState: ModifiedExtendedMenuItemT[] = null,
        updateOrderItems = false
    ) => {
        try {

            setLoading(true);

            const { reservation, id: orderId, order_items, buttons, ...restOfOrder } = newOrder;

            // Preparing the order details for the update
            const orderDetails = {
                ...restOfOrder,
                id: orderId,
            };

            let updatedOrder: OrderT;

            if (updateOrderItems) {

                // Preparing the order item options for the update
                const initialOptionItemsState = initialOrderItemsState?.flatMap((item) =>
                    item.options.map((option) => ({
                        unique_order_id: orderId,
                        unique_option_item_id: option.unique_option_item_id,
                        option_item_id: option.id || option.option_item_id,
                        option_group_id: option.option_group_id || option.optionGroupId,
                        quantity: option.quantity,
                        price: option.price,
                        optional_menu_items_group_id: option.optional_menu_items_group_id,
                        unique_menu_item_id: item.unique_id,
                    }))
                ) || [];

                const orderItemOptions = order_items.flatMap((item) =>
                    item.options.map((option) => ({
                        ...option,
                        unique_order_id: orderId,
                        unique_option_item_id: option.unique_option_item_id || crypto.randomUUID(),
                        option_item_id: option.id || option.option_item_id,
                        option_group_id: option.option_group_id || option.optionGroupId,
                        quantity: option.quantity,
                        price: option.price,
                        optional_menu_items_group_id: option.optional_menu_items_group_id,
                        unique_menu_item_id: item.unique_id,
                    }))
                );


                const result = await trpc.orders.updateOrderAndItems.mutate(
                    {
                        _order_id: orderId,
                        _order_details: orderDetails,
                        _order_items: order_items,
                        _order_item_options: orderItemOptions,
                        _initial_order_items_state: initialOrderItemsState,
                        _initial_option_items_state: initialOptionItemsState,
                    },

                );
                updatedOrder = result.data.updated_order as OrderT;
            } else {
                const { data } = await trpc.orders.updateOrder.mutate({
                    id: orderDetails.id,
                    data: orderDetails
                });
                updatedOrder = data[0] as unknown as OrderT;

            }

            setLoading(false);
            setGlobalState((state) => ({
                modalState: {
                    ...state.modalState,
                    state: {
                        ...state.modalState.state,
                        selectedOrderPopup: false,
                        orderActionsPopup: false,
                        openCancelOrderPopup: false,
                    },
                },
            }));
            set((state) => ({
                state: {
                    ...state.state,
                    selectedMenuItems: [],
                    selectedItemMode: ENUM_MODES.CREATE,
                    orderMode: ENUM_MODES.CREATE,
                },
            }));
            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_ORDER,
                payload: { updatedOrder },
            });
        } catch (error) {
            showErrorToast();
            setLoading(false);
        }
    };

    const handleConfirmAction = async (actionType: string) => {
        try {
            const reservation = get().state.reservation

            await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    id: reservation.id,
                    actions: actionType,
                },
            })

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_RESERVATION,
                payload: { updatedReservation: { ...reservation, actions: actionType } as ExtendedReservationT },
            });

            setGlobalState((state) => ({
                modalState: {
                    ...state.modalState,
                    state: {
                        ...state.modalState.state,
                        openConfirmActionPopup: false,
                        openCancelActionPopup: false,
                        paymentActionsPopup: false,
                        openActionsPopup: false,
                    },
                },
            }));
            set((state) => ({
                state: {
                    ...state.state,
                    actionType: actionType,
                },
            }));
        } catch (error) {
            showErrorToast();
            setLoading(false);

        }
    };

    const handleCompleteReservation = async () => {
        try {

            setLoading(true)
            const reservation = get().state.reservation

            await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    id: reservation.id,
                    status: STATUS_CONSTANTS.COMPLETED,
                },
            })


            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_RESERVATION,
                payload: { updatedReservation: { ...reservation, status: STATUS_CONSTANTS.COMPLETED, } },
            });

            setGlobalState((state) => ({
                modalState: {
                    ...state.modalState,
                    state: {
                        ...state.modalState.state,
                        openCompleteReservationPopup: false,
                    },
                },
            }));
            setLoading(false)
        } catch (error) {
            showErrorToast();
            setLoading(false);

        }
    };

    const handleViewItemWithOptions = (item: ModifiedExtendedMenuItemT, mode = ENUM_MODES.EDIT) => {
        set((state) => ({
            state: {
                ...state.state,
                selectedOrderSingleItem: item,
                selectedItemMode: mode,
            },
        }));
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    menuAddonsPopup: true,
                },
            },
        }));
    };

    const handleCloseMenuItemsPopup = (open: boolean) => {
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    menuAddonsPopup: open,
                },
            },
        }));
    };


    return {
        state: getInitialTablePageState(),
        initialOrderState: getInitialOrderState(),
        dispatch,
        setLoading,
        setToastNotification,
        handleCreateOrder,
        handleUpdateOrder,
        handleConfirmAction,
        handleCompleteReservation,
        handleViewItemWithOptions,
        handleCloseMenuItemsPopup,
        setSelectedMenuItems: createNestedSetter('selectedMenuItems'),
        setSelectedItemMode: createNestedSetter('selectedItemMode'),
        setOrderMode: createNestedSetter('orderMode'),
        setSelectedOrderSingleItem: createNestedSetter('selectedOrderSingleItem'),
        setActionType: createNestedSetter('actionType'),
        setSelectedOrder: createNestedSetter('selectedOrder'),
    };
};
